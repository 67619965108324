import React, { useEffect, useReducer } from "react";
import AppContext from "./appContext";
import { SelectedLang, DesignerData } from "../network/constants";
import Strings from "../values/strings";

export const GlobalState = (props) => {
  let lg = localStorage.getItem("lang");
  const initialState = {
    SelectedLang:
      lg === undefined || lg === null || lg === "null" ? "en-US" : lg,
    DesignerData: [],
  };
  /**
   * this is the reducer function which will handle the actions
   * @param {*} state
   * @param {*} action
   */
  function reducer(state, action) {
    console.log("action", action);
    switch (action.type) {
      case SelectedLang:
        return { ...state, SelectedLang: action.payload };
      case DesignerData:
        return { ...state, DesignerData: action.payload };
      default:
        return state;
    }
  }

  // using the useReducerHook
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
