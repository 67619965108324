import AppInfo from "./Kenya/AppInfo";
import ApplicantInfo from "./Kenya/ApplicantInfo";
import EVisaApplicant from "./Kenya/EVisaApplicant";
import Photo from "./Kenya/Photo";
import Travel from "./Kenya/Travel";

const RenderFormBasedOnStep = (props) => {
  const { activeStep } = props;
  switch (activeStep) {
    case 0:
      return <AppInfo />;
    case 1:
      return <EVisaApplicant />;
    case 2:
      return <Travel />;
    case 3:
      return <ApplicantInfo />;
    case 4:
      return <Photo />;
    default:
      return <ApplicantInfo />;
  }
};

export default RenderFormBasedOnStep;
