import _, { cloneDeepWith } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

/* Run below this code in console.to find which element is causing scrollbar*/
/*
javascript:(function(d){var w=d.documentElement.offsetWidth,t=d.createTreeWalker(d.body,NodeFilter.SHOW_ELEMENT),b;while(t.nextNode()){b=t.currentNode.getBoundingClientRect();if(b.right>w||b.left<0){t.currentNode.style.setProperty('outline','1px dotted red','important');console.log(t.currentNode);}};}(document));
 */

// Check whether URL is valid image file
const esCheckIfImageURLIsValid = async (imgUrl) => {
  return fetch(imgUrl)
    .then((res) => {
      if (res.status === 404) {
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    })
    .catch((err) => {
      return Promise.resolve(false);
    });
};

//let offset = moment().utcOffset('+º5:30');
const esFormatTime = (string, format = "DD-MMM-YYYY") => {
  return moment(string).utcOffset("+5:30").format(format);
  //return moment.utc(string).utcOffset(offset).format(format);
};

/*
  Below function remove trailing spaces of string values inside formDAta object,
  call before any form submit (see AddUsersDialog for usage)
 */
const esPreSubmit = (formValues, onSubmit) => {
  const trimmedFormValues = cloneDeepWith(formValues, (p) =>
    typeof p === "string" ? p.trim() : undefined
  );
  onSubmit(trimmedFormValues);
};

function getFileExtension(filename) {
  // get file extension
  const extension = filename.substring(
    filename.lastIndexOf(".") + 1,
    filename.length
  );
  return extension;
}

const getAllMatches = (source, regex) => {
  const matches = [];
  source.replace(regex, function () {
    matches.push({
      match: arguments[0],
      offset: arguments[arguments.length - 2],
      groups: Array.prototype.slice.call(arguments, 1, -2),
    });
    return arguments[0];
  });
  return matches;
};

// return "Employeee ID" => employeee_id , also removes special characters if any
const esLabelToKey = (str) => {
  str = str.replace(/[^\w\s]/gi, "");
  str = str.replace(/\s+/g, "_");
  str = str.toLowerCase();
  return str;
};

// https:stackoverflow.com/questions/16104078/appending-array-to-formdata-and-send-via-ajax
const convertModelToFormData = (
  val,
  formData = new FormData(),
  namespace = ""
) => {
  if (typeof val !== "undefined" && val !== null) {
    if (val instanceof Date) {
      formData.append(namespace, val.toISOString());
    } else if (val instanceof Array) {
      for (let i = 0; i < val.length; i++) {
        convertModelToFormData(val[i], formData, namespace + "[" + i + "]");
      }
    } else if (typeof val === "object" && !(val instanceof File)) {
      for (let propertyName in val) {
        if (val.hasOwnProperty(propertyName)) {
          convertModelToFormData(
            val[propertyName],
            formData,
            namespace ? `${namespace}[${propertyName}]` : propertyName
          );
        }
      }
    } else if (val instanceof File) {
      formData.append(namespace, val);
    } else {
      formData.append(namespace, val.toString());
    }
  }
  return formData;
};

//Get base 64 from file
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

/*
  for ex:  0-10 =>  A,B,C....J
 */
const getExcelColumnNameFromRange = (startRange = 0, endRange = 675) => {
  let finalResult = [];
  let _baseVal = "";
  for (startRange; startRange <= endRange; startRange++) {
    let remainder = startRange % 26;
    let initNumber = _.parseInt(startRange / 26, 10);
    let letter = String.fromCharCode(remainder + 65);

    if (initNumber !== 0 && remainder === 0) {
      if (_.isEmpty(_baseVal)) {
        _baseVal = String.fromCharCode(_.parseInt(remainder) + 65);
      } else {
        let tt = _baseVal.charCodeAt(0);
        _baseVal = String.fromCharCode(tt + 1);
      }
    }

    if (initNumber > 0) {
      letter = _baseVal + letter;
    }
    finalResult.push(letter);
  }
  return finalResult;
};

// It create a url based on host and its protocol
const getCurrentSocketURL = () => {
  let _protocol = _.isEqual(window.location.protocol, "https:") ? "wss" : "ws";
  let _full = `${_protocol}://${window.location.host}`;
  return _full;
  // return "wss://aira.technology";

};

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const getUpdatedLabel = (newLabel, nodes) => {
  let found = _.find(nodes, { data: { label: newLabel } }) ?? [];
  if (_.isEmpty(found)) {
    return newLabel;
  }

  let allIndexes = nodes.map((item, idx) => {
    let { label } = item.data;
    const regExString = `${newLabel} (\\d+$)`;
    const regex = new RegExp(regExString, "g");
    let _index = regex.exec(label)?.[1] ?? 0;
    return Number(_index);
  });
  let _incrementalCount = _.toNumber(_.max(allIndexes)) + 1;
  return `${newLabel} ${_incrementalCount}`;
};

// Below function generated foreground color as per given backgroundColor
function esGetContrastColor(
  hexcolor,
  darkFgColor = "#fff",
  lightFgColor = "rgba(18,18,18,0.7)"
) {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map(function (hex) {
        return hex + hex;
      })
      .join("");
  }

  // Convert to RGB value
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? lightFgColor : darkFgColor;
}

const esToastError = (errRes) => {
  let { error, status } = errRes;
  toast.error(`${status}: ${error}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const esIsJsonString = (str) => {
  let value = typeof str !== "string" ? JSON.stringify(str) : str;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === "object" && value !== null;
};

const toastErrorMessage = (data) => {
  switch (true) {
    case _.isArray(data):
      let msg = "";
      data.forEach((item, index) => {
        msg += `\n${item.field} : ${item.message}\n`;
      });
      toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case _.isObject(data):
      switch (true) {
        case !_.isEmpty(data.messages):
          let msg = "";
          data.messages.errors.forEach((item, index) => {
            msg += `\n${item.field} : ${item.message}\n`;
          });
          toast.error(msg);
          break;

        case _.isArray(data.errors):
          let msgArr = "";
          data.errors.forEach((item, index) => {
            msgArr += `\n${item.field} : ${item.message}\n`;
          });
          toast.error(msgArr);
          break;

        case _.isString(data.error):
          toast.error(data.error);
          break;
        case !_.isNil(data?.msg):
          toast.error(data.msg);
          break;
        default:
          toast.error(data.message ? data.message : data.sqlMessage);
          break;
      }
      break;
    case _.isString(data):
      toast.error(data);
      break;
    default:
      toast.error("Something Went Wrong");
  }
};

const generatePassword = (length = 8) => {
  let characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
  let generatedPass = "";
  for (let i = 0, n = characters.length; i < length; ++i) {
    generatedPass += characters.charAt(Math.floor(Math.random() * n));
  }
  return generatedPass;
};

export {
  convertModelToFormData,
  toastErrorMessage,
  esGetContrastColor,
  esLabelToKey,
  esPreSubmit,
  esCheckIfImageURLIsValid,
  esFormatTime,
  esToastError,
  esIsJsonString,
  generatePassword,
  getAllMatches,
  getBase64,
  uuidv4,
  getExcelColumnNameFromRange,
  getUpdatedLabel,
  getFileExtension,
  getCurrentSocketURL,
};
