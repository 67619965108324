/* This File contains function to setCommon used Api things everywhere  */

import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getRefreshToken } from "./networks";
import _ from "lodash";
import { toastErrorMessage } from "../common/esutil";
import { toast } from "react-toastify";
// import { esConnectionNameError } from "../common/esutil";
const initializeAxios = () => {
  /* Axios default set */
  const setToken = (updatedToken) => {
    return localStorage.setItem("token", updatedToken);
  };

  const setRefreshToken = (updatedRefreshToken) => {
    return localStorage.setItem("refresh_token", updatedRefreshToken);
  };

  // Function that will be called to refresh authorization
  const refreshAuthLogic = (failedRequest) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    if (failedRequest.response.status === 403) {
      toast.warning("You don't have permission to access this module.");
    } else {
      let refreshToken = localStorage.getItem("refresh_token");
      let formData = new FormData();
      formData.append("refresh_token", refreshToken);
      return axios
        .post(getRefreshToken, formData, config)
        .then((tokenRefreshResponse) => {
          setToken(tokenRefreshResponse.data.token);
          setRefreshToken(tokenRefreshResponse.data.refreshToken);
          failedRequest.response.config.headers["Authorization"] =
            "Bearer " + tokenRefreshResponse.data.token;
          return Promise.resolve();
        });
      //setTimeout(() => {
      // console.log("orange");
      // localStorage.removeItem("token");
      // localStorage.setItem("isSessionTimeOut", true);
      // window.location.assign("/login");
      // return null;
      /* Logging out for now as there is not any API for toke refresh   */
      //}, 1500);
    }
    /* Commenting for future use */
    /* Commenting for future use */
  };

  // Used For Adding The JSON Web Token
  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  let options = { statusCodes: [401, 403] };
  createAuthRefreshInterceptor(axios, refreshAuthLogic, options);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 403 || error.response?.status === 426) {
        return Promise.reject(error.response);
      } else if (error.response?.status === 404) {
        // Needs tp be corrected from backend , Inconsistency in format .

        if (!_.isEmpty(error.response.data)) {
          // toast.error(msg);
          toast.error(
            "Oops! Something went wrong, unable to connect to the server."
          );
        }
        console.log("error.response", error.response);
        return Promise.reject(error.response);
      } else if (error.response?.status === 502) {
        toast.error(
          "We Are Experiencing Technical Difficulties, please give it a try later."
        );
      } else {
        console.log("error.response", error.response);
        toastErrorMessage(
          error.response?.data?.message ??
          // error.response?.data.errors??  
            error.response?.data?.code ??
            error.response?.data??
            JSON.stringify(error.response?.data) ??
            "Something went wrong."
        );

        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
};

export const initializeApp = () => {
  initializeAxios();
  // Dispatch Redux Actions Here
};
