//eddysapata
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getRefreshToken } from "../network/networks";

const setToken = (updatedToken) => {
  return localStorage.setItem("token", updatedToken);
};

const axiosHeaderInstance = axios.create();
axiosHeaderInstance.interceptors.request.use(function (config) {
  let urlAccessToken = localStorage.getItem("url_token");
  config.headers.Authorization = `Bearer ${urlAccessToken}`;
  return config;
});

const refreshAuthLogic = (failedRequest) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("url_token")}` },
  };
  return axiosHeaderInstance
    .post(getRefreshToken, {}, config)
    .then((tokenRefreshResponse) => {
      setToken(tokenRefreshResponse.data.token);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token;
      return Promise.resolve();
    });
};

createAuthRefreshInterceptor(axiosHeaderInstance, refreshAuthLogic);

export { axiosHeaderInstance };
