import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { Controller, useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import * as yup from "yup";
import Images from "../../assets/index";
import { axiosHeaderInstance } from "../../common/axiosHeaderInstance";
import CommonButton from "../../components/CustomButtons/CommonButton";
import LoginFooter from "../../components/LoginFooter/LoginFooter";
import LoginHeader from "../../components/LoginHeader/LoginHeader";
import { resetpassword as ResetPasswordUrl } from "../../network/networks";
import Colors from "../../values/colors";
import Strings from "../../values/strings";
import { Wrapper } from "../Login/login.styles";

import { RiLockPasswordFill, RiLockPasswordLine } from "react-icons/ri";

const ResetPasswordSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .trim(Strings.no_space)
      .required(Strings.required_field),
    confirmPwd: yup
      .string()
      .trim(Strings.no_space)
      .min(6, Strings.min_Six_Char)
      .required(Strings.required_field)
      .oneOf([yup.ref("password")], "Passwords does not match"),
  })
  .required();

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [visible, setVisible] = useState(false);
  const [token, setToken] = useState("");
  const [viewErrorPage, setViewErrorPage] = useState(false);

  const location = useLocation();

  const {
    control: ResetPasswordControl,
    handleSubmit: ResetPasswordHandleSubmit,
    formState: { errors: ResetPasswordErrors },
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: {
      password: "",
      confirmPwd: "",
    },
  });

  const onResetPassword = (formData) => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 1500);
    callResetPasswordApi(formData);
  };

  const callResetPasswordApi = (data) => {
    if (_.isNull(localStorage.getItem("url_token"))) {
      toast.error("Not a valid url.");
      return;
    }
    const ResetPasswordFormData = {
      confirm_password: data.confirmPwd,
      password: data.password,
    };
    axiosHeaderInstance
      .post(ResetPasswordUrl, ResetPasswordFormData, { skipAuthRefresh: true })
      .then((response) => {
        console.log("response", response);
        setVisible(true);
        setTimeout(() => {
          window.location.assign("/app");
        }, 2000);
      })
      .catch((err) => {
        console.log("err", err, err.response);
        Swal.fire({
          title: '<span style="color:red">Error</span>',
          imageUrl: Images.error,
          imageAlt: "Custom image",
          html: err.response.data?.message || err.response.data[0]?.msg,
          showCloseButton: false,
        });
      });
  };
  useEffect(() => {
    let pathName = location.pathname;
    if (pathName == "/resetpassword/" && !_.isEmpty(location.search)) {
      let _token = location.search.split("?key=");
      localStorage.setItem("url_token", _token[1]);
    } else {
      setViewErrorPage(true);
    }
  }, [location]);

  return (
    <div>
      <LoginHeader />
      <Wrapper>
        <Grid container p={2}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Grid container pt={8} px={3}>
              {!viewErrorPage ? (
                <div className="ResetPassword-form">
                  <Grid item xs={12}>
                    <Collapse in={visible}>
                      <Alert
                        color="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                          >
                            x
                          </IconButton>
                        }
                      >
                        Password changed successfully!
                      </Alert>
                    </Collapse>
                    <Typography
                      component={"div"}
                      ml={{ xs: 0, md: 6 }}
                      sx={{ marginBottom: "30px", textAlign: "start" }}
                    >
                      <Typography
                        component="h2"
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "24px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          //color: "#200e32",
                          margin: "2px 0 15px",
                        }}
                      >
                        {Strings.enter_your_new_password}
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          root: {
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            letterSpacing: "normal",
                            color: "#200e32",
                            margin: "0",
                          },
                        }}
                      >
                        {Strings.val_password_validation_error_msg}{" "}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <form onSubmit={ResetPasswordHandleSubmit(onResetPassword)}>
                      <Grid container rowSpacing={3} ml={{ xs: 0, md: 6 }}>
                        <Grid item xs={12} md={10}>
                          <Controller
                            control={ResetPasswordControl}
                            name="password"
                            render={({ field }) => {
                              return (
                                <TextField
                                  {...field}
                                  fullWidth
                                  variant="standard"
                                  autoFocus
                                  type="password"
                                  placeholder={Strings.new_password}
                                  autoComplete="password"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton tabIndex={-1}>
                                          {/* <img
                                              src={Images.password}
                                              alt={Strings.password}
                                            /> */}
                                          <RiLockPasswordLine
                                            color={Colors.yellow}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          />
                          <ErrorMessage
                            errors={ResetPasswordErrors}
                            name="password"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <Controller
                            control={ResetPasswordControl}
                            name="confirmPwd"
                            render={({ field }) => {
                              return (
                                <TextField
                                  {...field}
                                  fullWidth
                                  variant="standard"
                                  type="password"
                                  placeholder={Strings.confirm_your_password}
                                  autoComplete="current-confirmPwd"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton tabIndex={-1}>
                                          {/* <img
                                              src={Images.confirm_password}
                                              alt={Strings.password}
                                            /> */}
                                          <RiLockPasswordFill
                                            color={Colors.yellow}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          />
                          <ErrorMessage
                            errors={ResetPasswordErrors}
                            name="confirmPwd"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <div className="buttons clearfix">
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <CommonButton
                                  fullWidth
                                  type="submit"
                                  disabled={disable}
                                  className="Me-connecter"
                                >
                                  {disable ? (
                                    <Dots color={Colors.white} />
                                  ) : (
                                    Strings.reset_my_password
                                  )}
                                </CommonButton>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </div>
              ) : (
                <div className="ResetPassword-form">
                  <Grid item xs={12}>
                    <Typography
                      component={"div"}
                      ml={{ xs: 0, md: 6 }}
                      sx={{ marginBottom: "30px", textAlign: "start" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "40px",
                          fontWeight: "200",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          //color: "#200e32",
                          fontFamily: "Poppins",
                          margin: "0",
                        }}
                      >
                        404
                      </Typography>
                      <Typography
                        component="h2"
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "24px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          //color: "#200e32",
                          margin: "2px 0 15px",
                        }}
                      >
                        Not Found
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          root: {
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            letterSpacing: "normal",
                            color: "#200e32",
                            margin: "0",
                          },
                        }}
                      >
                        {Strings.reset_password_error_message}
                      </Typography>
                    </Typography>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            display={{ xs: "none", md: "block" }}
          >
            <div className="img">
              <img src={Images.robot} alt={"Robot.png"} className="image" />
            </div>
          </Grid>
        </Grid>
      </Wrapper>
      <LoginFooter />
    </div>
  );
};

export default ResetPasswordPage;
