import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination, TableRow, Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import Images from "../../../assets";
import Search from "../../../components/Search/Search";
import ESTablePaginationAction from "../../../esCommonComponent/ESTablePaginationAction";
import { elisAddress } from "../../../network/networks";
import Colors from "../../../values/colors";
import '../styles.css';
import Strings from "../../../values/strings";
const costCenterHeader = [
  { id: "ID", label: Strings.id },
  { id: "NAME1", label: Strings.name },
  { id: "HOUSE_NUM1", label: Strings.street },
  { id: "STREET", label: Strings.house },
  { id: "POST_CODE1", label: Strings.post },
  { id: "CITY1", label: Strings.city },
  // { id: "LGORT", label: "LGORT" },
];
const createAddressData = (
  ID,
  NAME1,
  HOUSE_NUM1,
  STREET,
  POST_CODE1,
  CITY1,
  LGORT
) => {
  return {
    ID,
    NAME1,
    HOUSE_NUM1,
    STREET,
    POST_CODE1,
    CITY1,
    LGORT
  };
};
const AddressDialog = (props) => {
  const { onClose, open, onSelectItem, setShowDialogLoader, showDialogLoader, isWebEntry, pageContent } = props;
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchId, setSearchId] = useState("");
  const [searchEntity, setSearchEntity] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [noResultFound, setNoResultFound] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const [s_id, sets_id] = useState("")
  const [s_id_debounce] = useDebounce(s_id, 1500)
  const [s_hno, setS_hno] = useState("")
  const [s_hno_debounce] = useDebounce(s_hno, 1500)
  const [s_address, setS_address] = useState("")
  const [s_address_debounce] = useDebounce(s_address, 1500)
  const [s_postal, setS_postal] = useState("")
  const [s_postal_debounce] = useDebounce(s_postal, 1500)
  const [s_village, setS_village] = useState("")
  const [s_village_debounce] = useDebounce(s_village, 1500)

  const [selectedRow, setSelectedRow] = useState("");
  const formAxiosInstance = axios.create();
  formAxiosInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${isWebEntry ? pageContent.token.token : localStorage.getItem('token')}`;
    return config;
  });

  const fetchCostCenterList = useCallback(() => {
    setAddressData([])
    setIsFetchingData(true);
    let dataToSubmit = {
      limit: rowsPerPage,
      page: page + 1,
      // s_id: s_id_debounce,
      // s_hno_debounce: s_hno_debounce,
      // s_address: s_address_debounce,
      // s_postal: s_postal_debounce,
      // s_village: s_village_debounce,
    }


    formAxiosInstance.get(elisAddress, { params: dataToSubmit }).then((res) => {
      let { data, meta } = res.data;
      let { total } = meta
      setTotalCount(total);

      let _data = data.map((item) => {
        let _item = { ...item };
        return createAddressData(
          _item.ID,
          _item.NAME1,
          _item.HOUSE_NUM1,
          _item.STREET,
          _item.POST_CODE1,
          _item.CITY1,
          _item.LGORT,
        );
      });
      setNoResultFound(_.isEmpty(_data));
      setTimeout(() => {
        setAddressData(_data)
        setIsFetchingData(false);
      }, 1000);
    })
      .catch((err) => {
        setIsFetchingData(false);
      });
  }, [page, rowsPerPage]);
  const filterCostCenterList = useCallback(() => {
    setAddressData([])
    setIsFetchingData(true);
    let dataToSubmit = {
      limit: rowsPerPage,
      page: 1,
      s_id: s_id_debounce,
      s_hno_debounce: s_hno_debounce,
      s_address: s_address_debounce,
      s_postal: s_postal_debounce,
      s_village: s_village_debounce,
    }
    formAxiosInstance.get(elisAddress, { params: dataToSubmit }).then((res) => {
      let { data, meta } = res.data;
      let { total } = meta
      setTotalCount(total);

      let _data = data.map((item) => {
        let _item = { ...item };
        return createAddressData(
          _item.ID,
          _item.NAME1,
          _item.HOUSE_NUM1,
          _item.STREET,
          _item.POST_CODE1,
          _item.CITY1,
          _item.LGORT,
        );
      });
      setNoResultFound(_.isEmpty(_data));
      setTimeout(() => {
        setAddressData(_data)
        setIsFetchingData(false);
      }, 1000);
    })
      .catch((err) => {
        setIsFetchingData(false);
      });
  }, [rowsPerPage, s_address_debounce, s_hno_debounce, s_id_debounce, s_postal_debounce, s_village_debounce]);
  useEffect(() => {
    fetchCostCenterList();
  }, [fetchCostCenterList]);

  const onRowSelect = (item) => {
    setShowDialogLoader(true)
    let data = {
      label: item.NAME1,
      value: item.ID
    }
    onSelectItem(data)
    onClose()
  }
  const onClear = () => {
    setPage(0)
    sets_id("")
    setS_hno("")
    setS_address("")
    setS_postal("")
    setS_village("")
    fetchCostCenterList()
  }
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      fullWidth
      style={
        {
          zIndex: "9999999",
        }
      }
      disableEscapeKeyDown
    >
      <DialogTitle sx={{
        bordercostCentertom: "1px solid #e4e0e0", padding: "8px 10px",
        borderBottom: "1px solid #c9c6c6",
        marginBottom: "3px",
      }}>
        {Strings.address}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: "10px !important",
              top: "6px !important",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "10px !important" }}>
        {
          showDialogLoader &&
          <Backdrop
            sx={{ color: Colors.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showDialogLoader}
            onClick={() => setShowDialogLoader(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }

        <Grid container spacing={2}>
          <Grid item xs={3} md={3} sm={3} sx={{ paddingLeft: "5px !important" }}>
            <div style={{
              // height: "100%"
            }}>
              <div>
                <FormHelperText>{Strings.id}</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      sets_id(e.target.value);
                    }
                    sets_id(e.target.value);
                  }}
                  value={s_id}
                  placeholder={`${Strings.search}  ${Strings.id}`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>{Strings.house}</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_hno(e.target.value);
                    }
                    setS_hno(e.target.value);
                  }}
                  value={s_hno}
                  placeholer={`${Strings.search} ${Strings.house}`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>{Strings.address}</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_address(e.target.value);
                    }
                    setS_address(e.target.value);
                  }}
                  value={s_address}
                  placeholder={`${Strings.search}  ${Strings.address}`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>{Strings.post}</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_postal(e.target.value);
                    }
                    setS_postal(e.target.value);
                  }}
                  value={s_postal}
                  placeholer={`${Strings.search}  ${Strings.post}`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>{Strings.city}</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(0);
                    if (_.isEmpty(e.target.value)) {
                      setS_village(e.target.value);
                    }
                    setS_village(e.target.value);
                  }}
                  value={s_village}
                  placeholder={`${Strings.search} ${Strings.city}`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ textAlign: "end", marginTop: "10px" }}>
              <Button size="small" variant="outlined" autoFocus
                sx={{ mr: 1 }}
                onClick={() => {
                  setPage(0)
                  filterCostCenterList()
                }}>
                {Strings.apply}
              </Button>
              <Button size="small" variant="outlined" color="error" onClick={() => onClear()}>
                {Strings.clear}
              </Button>
            </div>
          </Grid>
          <Grid item xs={9} md={9} sm={9}>
            <div>
              <Card style={{ boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 1px 0px 1px rgb(0 0 0 / 14%), 0px -1px 0px 0px rgb(0 0 0 / 12%)" }}>
                <CardContent sx={{ padding: "0px !important" }}>
                  <TableContainer style={{ maxHeight: "500px" }}>
                    <Table stickyHeader>
                      <TableHead style={{ textTransform: "capitalize" }} >
                        <TableRow>
                          {costCenterHeader.map((headerItem, headerItemIndex) => {
                            return (
                              <TableCell
                                key={headerItemIndex}
                                style={{
                                  fontSize: "12px",
                                  textAlign: "left",
                                  fontFamily: "Inter",
                                  fontWeight: 600,
                                  whiteSpace: "nowrap",
                                  color: "white",
                                  backgroundColor: Colors.airaPrimary,
                                  padding: "10px"
                                }}
                              >
                                {headerItem.label}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {isFetchingData &&
                          _.range(rowsPerPage).map((rowNumber) => (
                            <TableRow key={rowNumber}>
                              {_.range(costCenterHeader.length).map((keyNumber) => (
                                <TableCell
                                  style={{ minWidth: "100px" }}
                                  className="p-1"
                                  key={`${rowNumber}.${keyNumber}`}>
                                  <Skeleton
                                    variant="rectangular"
                                    height={24}
                                    sx={{ borderRadius: "12px" }}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}

                        {!isFetchingData && noResultFound && (
                          <TableRow style={{ padding: '0px !important' }}>
                            <TableCell colSpan={8} className="p-1">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  src={Images.emptyState}
                                  style={{
                                    alignSelf: "center",
                                    //width: "100%",
                                    width: "300px",
                                    height: "300px",
                                  }}
                                  alt=""
                                />
                                <Typography
                                  component="span"
                                  className="styled-text Bonjour-Julien"
                                >
                                  Nothing here
                                </Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}


                        {addressData.map((rowItem, rowIdx) => {
                          return (
                            <TableRow
                              onClick={(e) => {
                                setSelectedRow(rowItem)
                                if (e.detail == 2) {
                                  onRowSelect(rowItem)
                                }
                              }}
                              hover
                              style={{
                                cursor: "pointer",
                                background: selectedRow.LGORT == rowItem.LGORT ? "#dad9d9" : "none"
                              }}
                              key={rowItem.costCenter_id}>
                              {costCenterHeader.map((costCenterHeaderItem, costCenterHeaderIdx) => {
                                let value = rowItem[costCenterHeaderItem.id];
                                return (
                                  <TableCell
                                    key={costCenterHeaderItem.id}
                                    className="p-2"
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "left",
                                      fontFamily: "Inter",
                                      fontWeight: 500,
                                      whiteSpace: "nowrap",
                                      // bordercostCentertom: "1px solid #eee",
                                    }}
                                  >
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div style={{ float: "right", fontFamily: "Inter" }}>
                    <TablePagination
                      labelRowsPerPage={Strings.rows_per_page}
                      component="div"
                      rowsPerPageOptions={[5, 10, 20]}
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={(event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                      }}
                      ActionsComponent={ESTablePaginationAction}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </DialogContent >
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={() => {
          if (!_.isEmpty(selectedRow))
            onRowSelect(selectedRow)
        }
        }>
          {Strings.validate}
        </Button>
        <Button variant="outlined" color="error" onClick={onClose}>
          {Strings.cancel}

        </Button>
      </DialogActions>
      {/* <DialogActions>
        <Button sx={{ width: '50%' }} disabled={loading} color="warning" variant="outlined" onClick={() => onClose()} >Cancel</Button>
        <Button sx={{ width: '50%' }} disabled={loading} color="primary" variant="outlined" onClick={() => onPressSave()} >Save</Button>
      </DialogActions> */}
    </Dialog >
  );
};

AddressDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AddressDialog;
