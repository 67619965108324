import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { Controller, useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss";
import * as yup from "yup";
import Images from "../../assets/index";
import CommonButton from "../../components/CustomButtons/CommonButton";
import ScreenLoader from "../../components/Loader/LoaderScreen";
import {
  GetSAMLStatus,
  SSOLogin,
  SSOSignOn,
  login as loginUrl,
  validateLicense,
} from "../../network/networks";
import Colors from "../../values/colors";
import Strings from "../../values/strings";
import { Wrapper } from "./login.styles";
import Visibility from "@mui/icons-material/Visibility";
import { Icon } from "@iconify/react";

const loginSchema = yup
  .object()
  .shape({
    username: yup
      .string()
      .trim(Strings.no_space)
      .email(Strings.enter_email)
      .required(Strings.required_field),
    password: yup
      .string()
      .trim(Strings.no_space)
      .min(6, Strings.min_Six_Char)
      .required(Strings.required_field),
  })
  .required();

const LoginPage = ({ toggleChangePassword }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [disableSSOLogin, setDisableSSOLogin] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);
  const queryString = window.location.search;
  const [showUserNotFound, setShowUserNotFound] = useState(false);
  const [showUserNotFoundMsg, setShowUserNotFoundMsg] = useState("");
  const [isValidatingLicense, setIsValidatingLicense] = useState(true);
  const [showSSOLogin, setShowSSOLogin] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    let _isSessionTimeOut = localStorage.getItem("isSessionTimeOut") ?? false;
    if (_.isEqual(_isSessionTimeOut, "true")) {
      setShowSessionTimeout(true);
    }
  }, []);
  const callGetSAMLStatus = useCallback(() => {
    axios
      .get(GetSAMLStatus, { skipAuthRefresh: true })
      .then((res) => {
        if (JSON.parse(res.data.status)) {
          setShowSSOLogin(true);
          onClickSSOLogin();
        }
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  }, []);
  useEffect(() => {
    axios
      .get(GetSAMLStatus, { skipAuthRefresh: true })
      .then((res) => {
        if (JSON.parse(res.data.status)) {
          setShowSSOLogin(true);
        }
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  }, []);
  const validateLicenseApi = useCallback(() => {
    axios
      .post(validateLicense)
      .then((res) => {
        console.log("res");
        setIsValidatingLicense(false);
      })
      .catch((err) => {
        if (err === "Network Error") {
          setShowUserNotFoundMsg(
            "We Are Experiencing Technical Difficulties, please give it a try later."
          );
          setShowUserNotFound(true);
        } else if (err.status === 403 || err.status === 426) {
          navigate("/importlicense", {
            state: { msg: err.data?.message },
          });
        }
        setTimeout(() => {
          setIsValidatingLicense(false);
        }, 1000);
      });
  }, []);

  useEffect(() => {
    validateLicenseApi();
  }, [validateLicenseApi]);

  useEffect(() => {
    if (window.location.pathname !== "/login") {
      if (queryString.includes("error") || queryString.includes("responseId")) {
        console.log("i am here");
      } else {
        callGetSAMLStatus();
      }
    }
  }, []);
  const {
    control: loginControl,
    handleSubmit: loginHandleSubmit,
    formState: { errors: loginErrors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onLogin = (formData) => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 1500);
    callLoginApi(formData);
  };

  const callLoginApi = (data) => {
    setShowUserNotFound(false);
    setShowUserNotFoundMsg("");
    setDisable(true);
    const loginFormData = {
      email: data.username,
      password: data.password,
    };

    axios
      .post(loginUrl, 
        loginFormData, { skipAuthRefresh: true })
      .then((response) => {
        localStorage.setItem("signinwith", "AIRA");
        localStorage.setItem("isSessionTimeOut", false);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("allData", JSON.stringify(response.data));
        localStorage.setItem("refresh_token", response.data.refreshToken);
        setShowSessionTimeout(false);
        setVisible(true);
        setDisable(false);
        setTimeout(() => {
          window.location.assign("/app/dashboard");
        }, 2000);
      })
      .catch((err) => {
        setDisable(false);
        if (err === "Network Error") {
          setShowUserNotFoundMsg(
            "We Are Experiencing Technical Difficulties, please give it a try later."
          );
          setShowUserNotFound(true);
        } else if (err.status === 403 || err.status === 426) {
          if (
            err.data?.user_id == "00000000000000000000000000000001" ||
            err.data?.user_id == 1
          ) {
            navigate("/importlicense", {
              state: { msg: err.data?.message },
            });
          } else {
            setShowUserNotFoundMsg(err.data?.message);
            setShowUserNotFound(true);
          }
        } else if (err.status === 401) {
          setShowUserNotFoundMsg(err.data?.message);
          setShowUserNotFound(true);
        } else {
          setShowUserNotFoundMsg(
            err.data?.message ??
              err.data?.[0]?.msg ??
              "Something went wrong with system, please try again after some time."
          );
          setShowUserNotFound(true);
        }
      });
  };
  const onClickSSOLogin = () => {
    setDisableSSOLogin(true);
    const headers = { Accept: "application/json" };
    axios
      .get(SSOLogin, headers, { skipAuthRefresh: true })
      .then((response) => {
        console.log(response.data);
        let windowRes = window.open(
          response.data?.login_url,
          "_self"
          // "location=yes,scrollbars=yes,status=yes"
        );
        let wnInterval = setInterval(() => {
          console.log("windowRes.closed", windowRes.closed);
          if (windowRes.closed) {
            clearInterval(wnInterval);
          }
        }, 3000);
        setDisableSSOLogin(false);
      })
      .catch((err) => {
        console.log("err", err.response);
        setDisableSSOLogin(false);
        // Swal.fire({
        //   title: '<span style="color:red">Error</span>',
        //   imageUrl: Images.error,
        //   imageAlt: "Custom image",
        //   html: err.response?.data.message || err.response?.data[0]?.message,
        //   showCloseButton: false,
        // });
      });
  };
  useEffect(() => {
    if (queryString.includes("responseId")) {
      let qtr = queryString.split("?responseId=");
      console.log("qtr", qtr[1]);
      localStorage.setItem("ssoResponseToken", qtr[1]);
      callSSOLoginAPI(qtr[1]);
    } else if (queryString.includes("error")) {
      let qtr = queryString.split("?error=");
      setShowUserNotFoundMsg(atob(qtr[1]));
      setShowSessionTimeout(false);
      setShowUserNotFound(true);
    } else {
    }
  }, [1]);
  const callSSOLoginAPI = (token) => {
    console.log("in callSSOLoginAPI");
    let data = { responseId: atob(token) };
    axios
      .post(SSOSignOn, data, { skipAuthRefresh: true })
      .then((response) => {
        console.log("response", response.data);
        if (response.status === 200) {
          localStorage.setItem("signinwith", "SAML");
          localStorage.setItem("isSessionTimeOut", false);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("allData", JSON.stringify(response.data));
          setShowSessionTimeout(false);
          setVisible(true);
          window.location.assign("/app/dashboard");
        } else {
          setShowUserNotFoundMsg("Something went wrong");
          setShowSessionTimeout(false);
          setShowUserNotFound(true);
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        setShowUserNotFoundMsg(err.response?.data?.error);
        setShowSessionTimeout(false);
        setShowUserNotFound(true);
        // window.location.assign("/login");
      });
  };
  return (
    <Wrapper>
      {isValidatingLicense ? (
        <ScreenLoader
          loading={isValidatingLicense}
          styleProp={{ height: "550px" }}
        />
      ) : (
        <Grid container p={2}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Grid container pt={8} px={3}>
              <div className="login-form">
                <Grid item xs={12}>
                  <Collapse in={showUserNotFound}>
                    <Alert
                      color="error"
                      className="mb-2"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setShowUserNotFound(false);
                          }}
                        >
                          x
                        </IconButton>
                      }
                    >
                      {showUserNotFoundMsg}
                    </Alert>
                  </Collapse>

                  <Collapse in={showSessionTimeout}>
                    <Alert
                      color="error"
                      className="mb-2"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setShowSessionTimeout(false);
                          }}
                        >
                          x
                        </IconButton>
                      }
                    >
                      {Strings.session_expired_message}
                    </Alert>
                  </Collapse>

                  <Collapse in={visible}>
                    <Alert
                      color="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                        >
                          x
                        </IconButton>
                      }
                    >
                      {Strings.login_success_message}
                    </Alert>
                  </Collapse>

                  <Typography
                    component={"div"}
                    ml={{ xs: 0, md: 6 }}
                    sx={{ marginBottom: "30px", textAlign: "start" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "40px",
                        fontWeight: "200",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        letterSpacing: "normal",
                        //color: "#200e32",
                        fontFamily: "Poppins",
                        margin: "0",
                      }}
                    >
                      {Strings.welcome_to_aira}
                    </Typography>
                    <Typography
                      component="h2"
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "24px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        letterSpacing: "normal",
                        //color: "#200e32",
                        margin: "2px 0 15px",
                      }}
                    >
                      {Strings.empower_tomorrow}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <form onSubmit={loginHandleSubmit(onLogin)}>
                    <Grid container rowSpacing={3} ml={{ xs: 0, md: 6 }}>
                      <Grid item xs={12} md={10}>
                        <Controller
                          control={loginControl}
                          name="username"
                          render={({ field }) => {
                            return (
                              <TextField
                                {...field}
                                fullWidth
                                variant="standard"
                                autoFocus
                                // type="email"
                                // placeholder={Strings.username}
                                placeholder={Strings.email}
                                autoComplete="username"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton tabIndex={-1}>
                                        <img
                                          src={Images.profileLogin}
                                          alt={Strings.username}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            );
                          }}
                        />
                        <ErrorMessage
                          errors={loginErrors}
                          name="username"
                          render={({ message }) => (
                            <p className="error-message">{message}</p>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <Controller
                          control={loginControl}
                          name="password"
                          render={({ field }) => {
                            return (
                              <TextField
                                {...field}
                                fullWidth
                                variant="standard"
                                type={isPasswordVisible ? "text" : "password"}
                                onChange={(e) => {
                                  field.onChange(e.target.value.trim());
                                }}
                                placeholder={Strings.password}
                                autoComplete="current-password"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton
                                        tabIndex={-1}
                                        onClick={() => {
                                          setIsPasswordVisible(!isPasswordVisible);
                                        }}
                                      >
                                        {isPasswordVisible ? (
                                          <Icon
                                            icon="material-symbols:visibility-rounded"
                                            color={Colors.airaSecondary}
                                          />
                                        ) : (
                                          <Icon
                                            icon="mdi:visibility-off"
                                            color={Colors.airaSecondary}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Typography
                                        className="frgt-pass"
                                        component={"label"}
                                        onClick={(event) =>
                                          toggleChangePassword(event)
                                        }
                                      >
                                        {Strings.forgot_your_password}
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            );
                          }}
                        />
                        <ErrorMessage
                          errors={loginErrors}
                          name="password"
                          render={({ message }) => (
                            <p className="error-message">{message}</p>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <div className="buttons">
                          <Stack gap={1} width={"85%"}>
                            <CommonButton
                              fullWidth
                              type="submit"
                              disabled={disable}
                              className="Me-connecter"
                            >
                              {disable ? (
                                <Dots color={Colors.white} />
                              ) : (
                                Strings.login
                              )}
                            </CommonButton>
                            <CommonButton
                              type="button"
                              fullWidth
                              disabled={disable}
                              className="googleSignIn text-nowrap"
                            >
                              <img src={Images.google} alt="profile" />
                              {Strings.sign_in_with_google}
                            </CommonButton>

                            {showSSOLogin && (
                              <Grid item xs={12} md={12}>
                                <CommonButton
                                  type="button"
                                  fullWidth
                                  disabled={disableSSOLogin}
                                  className="googleSignIn text-nowrap"
                                  onClick={() => onClickSSOLogin()}
                                >
                                  {/* <img src={Images.google} alt="profile" /> */}
                                  {disableSSOLogin ? (
                                    <Dots color={Colors.airaPrimary} />
                                  ) : (
                                    Strings.sign_in_with_sso
                                  )}
                                  {/* {Strings.sign_in_with_sso} */}
                                </CommonButton>
                              </Grid>
                            )}
                          </Stack>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            display={{ xs: "none", md: "block" }}
          >
            <div className="img">
              <img src={Images.robot} alt={"Robot.png"} className="image" />
            </div>
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};

export default LoginPage;
