import { ErrorMessage } from "@hookform/error-message";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Alert,
  Button,
  Collapse,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { BallTriangle, ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Images from "../../assets";
import LoginHeader from "../../components/LoginHeader/LoginHeader";
import { importLicenseApi } from "../../network/networks";
import Colors from "../../values/colors";
import { Wrapper } from "../Login/login.styles";
import LogoMonogram from "../../components/Loader/LogoMonogram";
import Strings from "../../values/strings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flexgrow: 1;
  padding: 3%;
  //background-color:#eee;
  justify-content: center;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  border-radius: 10px;
  border-width: 1px;
  transition: all 0.24s ease-in-out;
  background: rgba(145, 158, 171, 0.08);
`;

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return props.muiTheme.palette.divider;
};
const ACCEPT_FILETYPE = {
  "application/octet-stream": [".lic"],
};
const ImportLicense = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const [files, setFiles] = useState();
  const [loader, setLoader] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isBackPressed, setIsBackPressed] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const muiTheme = useTheme();
  const onSubmit = async (data) => {
    setLoader(true);
    const licData = new FormData();
    licData.append("private_key_path", data.private_key_path);
    licData.append(`file`, data.file);
    licData.append(`passphrase`, data.passphrase);
    axios
      .post(importLicenseApi, licData)
      .then((response) => {
        setIsSuccess(true);
        setSuccessMsg("License imported successfully.");
        setTimeout(() => {
          setIsSuccess(false);
          setSuccessMsg("");
          setLoader(false);
          navigate("/login", { replace: true });
        }, 2000);
      })
      .catch((error) => {
        console.log(error.response);
        setErrorMsg(error.response?.data?.message ?? "Something went wrong.");
        setShowError(true);
        setLoader(false);
      });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setIsError(false);
    setErrMsg("");

    setFileUploading(true);
    let _file = acceptedFiles[0];
    _file.preview = URL.createObjectURL(acceptedFiles[0]);
    setValue("file", _file);
    setTimeout(() => {
      setFiles(_file);
      setFileUploading(false);
    }, 1000);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({ accept: ACCEPT_FILETYPE, onDrop });

  useEffect(() => {
    if (!_.isNil(location.state?.msg)) {
      setIsError(true);
      setErrMsg(location.state?.msg);
    }
  }, [location]);
  return (
    <>
      <div style={{ height: "100vh" }}>
        <LoginHeader />

        <Wrapper>
          <Grid container p={2}>
            <Grid item xs={12} md={12} order={{ xs: 2, md: 1 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "68vh",
                  alignItems: "center",
                }}
              >
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{
                    padding: "0 48px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Paper elevation={2} sx={{ p: 4 }}>
                    <LogoMonogram />
                    <br />
                    {isSuccess ? (
                      <Collapse in={isSuccess}>
                        <Alert
                          color="success"
                          className="mb-2"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setIsSuccess(false);
                              }}
                            >
                              x
                            </IconButton>
                          }
                        >
                          {successMsg}
                        </Alert>
                      </Collapse>
                    ) : (
                      <>
                        <div className="login-form">
                          <Grid item xs={12}>
                            <Typography
                              component={"div"}
                              sx={{
                                marginBottom: "30px",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "6%",
                                  justifyContent: !_.isEmpty(files)
                                    ? isBackPressed
                                      ? "center"
                                      : "left"
                                    : "center",
                                }}
                              >
                                {!_.isEmpty(files) && !isBackPressed && (
                                  <IconButton
                                    sx={{ padding: "10px" }}
                                    onClick={() => {
                                      setIsBackPressed(true);
                                      // setValue("file", "");
                                      // setFiles({});
                                    }}
                                  >
                                    <ArrowBackIcon />
                                  </IconButton>
                                )}
                                <Typography
                                  component="h2"
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontSize: "24px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    letterSpacing: "normal",
                                    color: "rgb(106 114 122)",
                                    margin: "2px 0 15px",
                                    marginLeft: !_.isEmpty(files)
                                      ? isBackPressed
                                        ? "0"
                                        : "20%"
                                      : "0",
                                  }}
                                >
                                  {Strings.import_license}
                                </Typography>
                              </div>
                            </Typography>
                          </Grid>
                        </div>
                        <Collapse in={isError}>
                          <Alert
                            color="error"
                            className="mb-2"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsError(false);
                                }}
                              >
                                x
                              </IconButton>
                            }
                          >
                            {errMsg}
                          </Alert>
                        </Collapse>

                        <Collapse in={showError}>
                          <Alert
                            color="error"
                            className="mb-2"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setShowError(false);
                                }}
                              >
                                x
                              </IconButton>
                            }
                          >
                            {errorMsg}
                          </Alert>
                        </Collapse>
                        <div className="animate__animated animate__zoomIn">
                          {_.isEmpty(files) && !isBackPressed && (
                            <Controller
                              name={"file"}
                              id={"file"}
                              rules={{
                                required: `File is required`,
                              }}
                              control={control}
                              render={({ field }) => (
                                <Container
                                  muiTheme={muiTheme}
                                  {...getRootProps({
                                    isDragAccept,
                                    isFocused,
                                    isDragReject,
                                  })}
                                >
                                  <input {...getInputProps()} />
                                  <div
                                    style={{
                                      display: "block",
                                      textAlign: "center",
                                      color: "rgb(145, 158, 171)",
                                    }}
                                  >
                                    {!fileUploading && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "4px",
                                          // -webkit-box-align: "center",
                                          alignItems: "center",
                                          color: " rgb(145, 158, 171)",
                                          userSelect: "none",
                                        }}
                                      >
                                        <img
                                          src={Images.uploadFileCloud}
                                          // src="/media/svg/icons/Files/UploadFileCloud.svg"
                                          height={50}
                                          width={50}
                                          alt="Upload File"
                                          style={{
                                            height: "50px",
                                            userSelect: "none",
                                          }}
                                        />
                                      </div>
                                    )}
                                    <Typography
                                      component="div"
                                      variant="body1"
                                      align="center"
                                      // className="mt-1 text-nowrap"
                                    >
                                      {isDragActive ? (
                                        <div>
                                          {isDragAccept ? (
                                            <p>Drop the file here ...</p>
                                          ) : (
                                            <div
                                              style={{
                                                color: Colors.bootstrapDanger,
                                              }}
                                            >
                                              File not Supported
                                            </div>
                                          )}
                                        </div>
                                      ) : fileUploading ? (
                                        <>
                                          <BallTriangle
                                            height={60}
                                            width={60}
                                            radius={5}
                                            color={Colors.airaPrimary}
                                            ariaLabel="ball-triangle-loading"
                                            wrapperClass={{}}
                                            wrapperStyle={{
                                              display: "block",
                                            }}
                                            visible={true}
                                          />
                                          <br />
                                          <Typography>
                                            File uploading is in progress.
                                          </Typography>
                                        </>
                                      ) : (
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            fontWeight: "700",
                                            fontFamily: "system-ui",
                                            // marginBottom: "10px",
                                          }}
                                        >
                                          Upload License
                                        </Typography>
                                      )}
                                    </Typography>
                                  </div>
                                </Container>
                              )}
                            />
                          )}
                          <ErrorMessage
                            errors={errors}
                            name={"file"}
                            render={({ message }) => (
                              <FormHelperText style={{ color: Colors.red }}>
                                {message}
                              </FormHelperText>
                            )}
                          />

                          {!_.isEmpty(files) && isBackPressed && (
                            <div
                              style={{
                                display: "flex",
                                margin: "2% 0px",
                                border: "1px dashed gray",
                                padding: "3px",
                                borderRadius: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              className="animate__animated animate__fadeIn"
                            >
                              <div style={{ display: "flex" }}>
                                <img
                                  alt=""
                                  src={Images.certificate}
                                  style={{
                                    padding: "5px",
                                    height: "45px",
                                    marginTop: "10px",
                                  }}
                                  onLoad={() => {
                                    URL.revokeObjectURL(files.preview);
                                  }}
                                />
                                <div style={{ padding: "5px", margin: "10px" }}>
                                  <Typography>{files.name}</Typography>
                                </div>
                              </div>
                              <IconButton
                                onClick={() => {
                                  setIsBackPressed(false);
                                  setValue("file", "");
                                  setFiles({});
                                }}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </div>
                          )}
                        </div>

                        {!_.isEmpty(files) && !isBackPressed && (
                          <>
                            <div className="animate__animated animate__fadeIn">
                              <div className="mb-2">
                                <TextField
                                  fullWidth
                                  id="passphrase"
                                  label="Passphrase"
                                  type="text"
                                  autoComplete="current-password"
                                  name="passphrase"
                                  {...register("passphrase", {
                                    required: `Passphrase Is Required`,
                                  })}
                                  size="small"
                                  sx={{ mt: 1 }}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={"passphrase"}
                                  render={({ message }) => (
                                    <FormHelperText
                                      style={{ color: Colors.red }}
                                    >
                                      {message}
                                    </FormHelperText>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="animate__animated animate__fadeIn">
                              <div className="mb-2">
                                <TextField
                                  fullWidth
                                  id="private_key_path"
                                  label="Path"
                                  type="text"
                                  autoComplete="current-password"
                                  name="private_key_path"
                                  {...register("private_key_path", {
                                    required: `Path Is Required`,
                                  })}
                                  size="small"
                                  sx={{ mt: 1 }}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={"private_key_path"}
                                  render={({ message }) => (
                                    <FormHelperText
                                      style={{ color: Colors.red }}
                                    >
                                      {message}
                                    </FormHelperText>
                                  )}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {!_.isEmpty(files) && !isBackPressed && (
                          <div item xs={2} sm={2} style={{ textAlign: "end" }}>
                            <Button
                              variant="outlined"
                              type="submit"
                              style={{ marginTop: "2%" }}
                              disabled={loader}
                            >
                              {loader ? (
                                <ThreeDots
                                  height="30"
                                  width="30"
                                  radius="9"
                                  color={Colors.airaPrimary}
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </Paper>
                </form>
              </div>
            </Grid>
          </Grid>
        </Wrapper>
        <div
          // className="footer"
          style={{
            position: "fixed",
            width: "100%",
            bottom: "0px",
            padding: "2% 2%",
            textAlign: "end",
            fontFamily: "Poppins",
          }}
        >
          <Typography style={{ fontFamily: "Poppins" }}>
            &copy; AIRA {new Date().getFullYear()}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ImportLicense;
