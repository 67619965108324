import axios from "axios";
import { baseurl } from "../networks";
const register = (username, email, password) => {
  return axios.post(baseurl + "signup", {
    username,
    email,
    password,
  });
};
const login = (username, password) => {
  return axios
    .post(baseurl + "auth/login", {
      email: username,
      password,
    })
    .then((res) => {
      if (res.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(res.data));
      }
      return res.data;
    });
};
const logout = () => {
  let final = localStorage.removeItem("user");
};
const authService = {
  register,
  login,
  logout,
};
export default authService;
