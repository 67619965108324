export const POST = "POST";
export const PUT = "PUT";
export const GET = "GET";
export const PATCH = "PATCH";
export const DELETE = "DELETE";
export const isDev = false;
export const SelectedLang = "SelectedLang";
export const DesignerData = "DesignerData";
export const API_MAP_KEY = "AIzaSyAWf5kIQ93f-ZGURSM3XnpKyyD1DPtYPnc";
export const GEO_FENCING_URL = `https://aira.technology/cnh.kml?timestamp=${new Date().getTime()}`;
export const PROFILE_IMAGE = "PROFILEIMAGE"; // key for profile image ModifyMyProfile.js
