import styled from "@emotion/styled";

export const Wrapper = styled.div`
  :root {
    --blue: #0676ed;
    --green: #7fb97a;
    --pink: #ffa1c0;
    --orange: #fe8766;
    --purple: #6755df;
    --grey: #808291;
    --light_grey: #e4e4e4;
    --yellow: #ffaf00;
    --black: #000000;
    --eggplant: #200e32;
    --white: #ffffff;
    --dark-royal-blue: #001973;
    --pale-grey: #ecf5fa;
    --red: #fa0000;
    --grape-purple: #411d66;
  }

  /*  Input Classes */

  .registerInput {
    background: #f6f6f6;
    ${"" /* padding: 17px 10px; */}
    border: 1px solid #f6f6f6;
    border-radius: 9px;
    font-size: 12px;
    width: 100%;
    cursor: pointer;
  }
  .registerInput:focus {
    background: white;
    outline: none;
    border: 1px solid #7d00e1;
    box-shadow: none;
  }
  .registerSelectInput {
    background: #f6f6f6;
    padding: 10px 10px;
    border: 1px solid #f6f6f6;
    border-radius: 9px;
    width: 100%;
  }
  .registerSelectInput:focus {
    background: white;
    border: 1px solid #6755df;
    border-radius: 9px;
  }
  .error-message {
    font-size: 12px;
    margin: 4px;
    color: red;
  }

  /** End */

  .imageBlock {
    background: white;
  }
  .Bienvenue-sur-AIRA {
    font-size: 40px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #200e32;
    font-family: "Poppins";
    margin: 0;
  }
  .Autonomisez-demain {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #200e32;
    margin: 2px 0 15px;
  }
  .Cest-votre-premire {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #200e32;
    margin: 0;
  }
  .Cest-votre-premire a {
    color: #0676ed;
  }
  .card {
    border: none !important;
  }
  .form-control {
    border: none;
  }
  .input-group-text {
    border: none;
    border-radius: 0;
    padding: 0;
    background-color: white;
  }
  .input-group {
    padding: 7px 0px;
    border-bottom: 1px solid #808291;
  }
  .buttons {
    margin: 30px 0 0;
  }
  /* .buttons .button:nth-child(1) {
    width: 168px;
    float: left;
  }
  .buttons .button:nth-child(2) {
    width: 264px;
    float: right;
  } */
  button:focus {
    box-shadow: none !important;
  }
  .buttons .button button {
    width: 100%;
  }
  .Me-connecter,
  .googleSignIn {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-radius: 15px;
    height: 45px;
    transition: all 0.3s;
    padding: 10px 12px;
  }
  .Me-connecter {
    color: #ffffff;
    background-color: #6755df;
    border: 1px solid transparent;
  }
  .Me-connecter:hover {
    background: #fff;
    color: #6755df;
    border-color: #6755df;
  }

  .googleSignIn {
    color: #6755df;
    background: #fff;
    position: relative;
    border: 1px solid #6755df;
  }
  .googleSignIn img {
    height: 24px;
    margin-right: 15px;
  }
  .googleSignIn:hover {
    color: #fff;
    background: #6755df;
  }
  .googleSignIn:hover img {
    filter: brightness(0.5);
  }
  .cardBlock {
    margin: auto;
  }
  .inputCards:focus {
    border: none;
    background: none;
  }
  .form-control:focus {
    color: #5c6873;
    background-color: #fff;
    background: none;
    border: none;
    border-color: #8ad4ee;
    outline: 0;
    box-shadow: none !important;
  }
  .modal-content {
    border-radius: 1rem;
    padding: 15px;
  }
  .modal-header .close:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .modal-header .close {
    background: white;
    border-radius: 25px;
    box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.07);
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    font-size: 22px;
    opacity: 1;
    color: #3a3a3a;
    margin: -5px -5px 0 0;
  }
  .registerInput {
    background: #f6f6f6;
    padding: 20px 10px;
    border: 2px solid transparent;
    border-radius: 10px;
    font-size: 13px !important;
    height: 50px;
  }
  .registerInput:focus {
    background: #fff;
    border: 2px solid #6755df;
    border-radius: 9px;
  }
  .modal-header {
    border-bottom: none !important;
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
  .validate {
    min-width: 200px;
    margin: 0px 15px;
  }
  .cancel {
    margin: 0px 15px;
  }
  .signUpButton {
    background: none;
    border: none;
    color: #0676ed;
    font-weight: 600;
  }
  .signUpButton:focus {
    outline: none;
    box-shadow: none;
  }
  .loginInputs :focus {
    background-color: white;
  }
  .loginInputs {
    background-color: white;
  }
  .loginInputs:-internal-autofill-selected {
    background-color: white;
  }
  .registerSelectInput {
    background: #f6f6f6;
    border: 1px solid #f6f6f6;
    border-radius: 9px;
    height: 50px;
  }
  .registerSelectInput:focus {
    background: white;
    border: 1px solid #6755df;
    border-radius: 9px;
  }
  .modal-title {
    font-weight: 800;
    font-size: 16px;
    color: #200e32;
  }
  .enterEmailToResetPassword {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #200e32;
  }
  .tCenter {
    text-align: center;
  }
  .ThankYou {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #200e32;
  }
  .resetLinkMsg {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #200e32;
    margin: 7% auto;
  }

  .RegErrMsg {
    font-size: 10px;
    color: red;
    margin-bottom: 3px;
  }
  .PhoneInputInput {
    height: 40px;
    border: none;
    background: #f6f6f6;
    padding-left: 5px;
    font-size: 12px;
  }
  .PhoneInputInput:focus {
    outline: none;
    box-shadow: none;
    background-color: #f6f6f6;
  }
  .PhoneInput {
    padding: 5px;
    background-color: #f6f6f6;
    border-radius: 5px;
  }

  /******************/
  .input-group-prepend {
    margin-right: 2px;
  }
  .login-wrapper {
    max-width: 1176px;
    margin-left: auto;
    width: 100%;
  }
  .login-wrapper .login-img {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .login-wrapper .login-form {
    max-width: 448px;
    width: 100%;
    flex: 1;
    padding: 40px 0 0;
  }
  .login-wrapper .img {
    flex: 1;
    text-align: right;
    padding-right: 53px;
  }
  .login-wrapper .img img {
    width: auto;
    height: 605px;
  }
  .login-form,
  .img {
    // padding: 0 30px;
  }

  .login-head {
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 15px;
  }
  .frgt-pass {
    background: transparent;
    padding: 0;
    border: 0;
    color: #0676ed;
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0;
    transition: all 0.3s;
  }
  .frgt-pass:hover {
    text-decoration: underline;
    background: transparent;
    color: #0676ed;
  }
  .frgt-pass:focus,
  .btn-secondary:not(:disabled):not(.disabled):active {
    background: transparent;
    border: 0;
    box-shadow: none;
    outline: none !important;
  }
  /*******modal********/
  .modal-dialog {
    max-width: 550px;
  }
  .form-text {
    display: block;
    margin: 0 0 8px;
    font-size: 11px;
  }
  .register-form-modal {
    .Me-connecter,
    .googleSignIn {
      width: 100%;
    }
    .googleSignIn {
      margin-bottom: 10px;
    }
    .modal-button {
      margin: 30px 0 0;
    }
    .row.form-group {
      margin-bottom: 0;
      .col-12 {
        margin-bottom: 15px;
      }
    }
    .modal-button .col-12 {
      margin: 0 !important;
    }
  }

  /*****************modal alert box************/
  .login-alert-modal {
    max-width: 400px;
    text-align: center;
  }
  .login-alert-modal .body {
    text-align: center;
    background: #fff;
    padding: 20px 20px 30px;
    border-radius: 20px;
  }

  .login-alert-modal img {
    width: 100%;
    margin: -42px 0 0;
  }

  .login-alert-modal .txt h2 {
    color: #7fca87;
    font-size: 18px;
    margin: 15px 0;
    font-weight: 600;
  }
  .login-alert-modal .txt p {
    margin: 0;
    color: #200e32;
    font-weight: 400;
    font-size: 12px;
  }
  .login-alert-modal .txt button {
    margin: 25px 0 0;
    background: #6755df;
    color: #fff;
    height: 50px;
    border: 0;
    border-radius: 13px;
    padding: 0 30px;
    font-size: 14px;
    max-width: 150px;
    width: 100%;
  }
  /*******************media query******/
  @media screen and (max-width: 1439px) {
    .header {
      padding: 2em 0 0;
    }
    .login-wrapper .login-form {
      padding: 0;
    }
    .login-wrapper .img img {
      height: 540px !important;
    }
    .login-wrapper .img {
      text-align: center;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1318px;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1192px) {
    .login-wrapper .img img {
      height: 550px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .login-img .img {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .custom-dropdown .dropdown-menu {
      min-width: 280px;
    }
    .custom-dropdown .dropdown-menu .dropdown-item,
    .c-dropdown .dropdown-menu {
      padding: 12px 10px;
    }
    .Me-connecter,
    .googleSignIn {
      margin-bottom: 5px;
    }
    .login-img {
      margin: 0;
      display: block;
    }
    .login-form,
    .img {
      padding: 0;
    }
    .login-img {
      padding: 5em 0;
      height: calc(100vh - 85px);
      .img {
        display: none;
      }
    }
    button.frgt-pass {
      font-size: 12px;
    }
    .Bienvenue-sur-AIRA {
      font-size: 2em;
    }
    .Autonomisez-demain {
      font-size: 1.2em;
    }
  }

  @media screen and (max-width: 576px) {
    .modal-button .col-12 {
      padding: 0;
    }
  }

  @media screen and (max-width: 476px) {
    .buttons .button {
      width: 100% !important;
      margin: 0;
    }
    /* .buttons .button:nth-child(1) {
      margin-bottom: 10px;
    } */
    .Me-connecter,
    .googleSignIn {
      border-radius: 10px;
      height: 45px;
    }
    .googleSignIn img {
      height: 22px;
    }
  }
`;
