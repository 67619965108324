import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

const recordSlice = createSlice({
  name: "isrecording",
  initialState,
  reducers: {
    setIsRecording: (state, action) => {
      state = action.payload;
      return state;
    },
  },
},
);

const { reducer, actions } = recordSlice;
export const { setIsRecording } = actions;
export default reducer;
