import { ErrorMessage } from "@hookform/error-message";
import { FormHelperText, Grid, Typography } from "@mui/material";
import axios from "axios";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import {
  fileUpload,
  getFileDetail,
  getImagePath,
  webentryGetFormContent,
} from "../../../../../../../network/networks";
import { useEffect, useState } from "react";
import Colors from "../../../../../../../values/colors";
import _ from "lodash";
import { toast } from "react-toastify";
const UploadUI = styled.ul`
  input[type="file"] {
    width: 100%;
    max-width: 100%;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #555;
  }
  input[type="file"]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #084cdf;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }
  input[type="file"]::file-selector-button:hover {
    background: #0d45a5;
  }
`;
const Photo = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [appToken, setAppToken] = useState("");
  useEffect(() => {
    const queryString = window.location.search;
    if (queryString.includes("?code=")) {
      let _splitted = queryString.split("?code=");
      if (!_.isNil(_splitted[0])) {
        let getFormAxiosInstance = axios.create();
        getFormAxiosInstance
          .get(`${webentryGetFormContent}${_splitted[1]}`)
          .then(async (res) => {
            setAppToken(res.data?.token?.token);
          })
          .catch((err) => {});
      }
    } else {
      setAppToken(localStorage.getItem("token"));
    }
  }, [1]);
  const onChangeFile = (event, name) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    const requestOptions = {
      method: "POST",
      headers: { Authorization: `Bearer ${appToken}` },
      body: formData,
    };

    fetch(fileUpload, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch(getFileDetail(data[0]), {
          method: "GET",
          headers: { Authorization: `Bearer ${appToken}` },
        })
          .then((_response) => _response.json())
          .then((res) => {
            setValue(`${name}`, getImagePath(res.name));
            setLoaderValue();
          })
          .catch((err) => {
            setLoaderValue();
            toast.error("Something went wrong. Please try again later.");
          });
      })
      .catch((err) => {
        setLoaderValue();
        toast.error("Something went wrong. Please try again later.");
      });
  };
  const setLoaderValue = () => {
    setLoader(false);
    setLoader1(false);
    setLoader2(false);
    setLoader3(false);
  };
  useEffect(() => {
    console.log("getValues", getValues());
  });
  return (
    <div>
      <Typography variant="h6">Photo</Typography>

      <div className="form-group">
        <FormHelperText color="muted">
          {"Recent Passport Size Photo"}
        </FormHelperText>
        <Controller
          rules={{ required: "This field is required." }}
          name={"file"}
          id={"file"}
          control={control}
          render={({ field }) => {
            return (
              <UploadUI>
                {loader && (
                  <p style={{ color: Colors.red }}>
                    Image uploading in progress....
                  </p>
                )}
                <input
                  type="file"
                  id="images"
                  accept="image/*"
                  onChange={(event) => {
                    setLoader(true);
                    onChangeFile(event, "file");
                  }}
                />
              </UploadUI>
            );
          }}
        />
        <ErrorMessage
          errors={errors}
          name={"file"}
          render={({ message }) => (
            <FormHelperText
              style={{
                color: "#bf1650",
              }}
            >
              {message}
            </FormHelperText>
          )}
        />
      </div>
      <br />
      <Typography variant="h6">{"Upload Legal Documents"}</Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Upload Front Page Of Passport"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"passportFrontPage"}
              id={"passportFrontPage"}
              control={control}
              render={({ field }) => {
                return (
                  <UploadUI>
                    {loader1 && (
                      <p style={{ color: Colors.red }}>
                        Image uploading in progress....
                      </p>
                    )}
                    <input
                      type="file"
                      id="images"
                      accept="image/*"
                      onChange={(event) => {
                        setLoader1(true);
                        onChangeFile(event, "passportFrontPage");
                      }}
                    />
                  </UploadUI>
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"file"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Upload Back Page Of Passport"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"passportBackPage"}
              id={"passportBackPage"}
              control={control}
              render={({ field }) => {
                return (
                  <UploadUI>
                    {loader2 && (
                      <p style={{ color: Colors.red }}>
                        Image uploading in progress....
                      </p>
                    )}
                    <input
                      type="file"
                      id="images"
                      accept="image/*"
                      onChange={(event) => {
                        setLoader2(true);
                        onChangeFile(event, "passportBackPage");
                      }}
                    />
                  </UploadUI>
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"file"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
      </Grid>
      <br />

      <Typography variant="h6">Travel Insurance</Typography>
      <div className="form-group">
        <FormHelperText color="muted">{"Travel  Insurance"}</FormHelperText>
        <Controller
          rules={{ required: "This field is required." }}
          name={"travelInsurance"}
          id={"travelInsurance"}
          control={control}
          render={({ field }) => {
            return (
              <UploadUI>
                {loader3 && (
                  <p style={{ color: Colors.red }}>
                    Image uploading in progress....
                  </p>
                )}
                <input
                  type="file"
                  id="images"
                  accept="image/*"
                  onChange={(event) => {
                    setLoader3(true);
                    onChangeFile(event, "travelInsurance");
                  }}
                />
              </UploadUI>
            );
          }}
        />
        <ErrorMessage
          errors={errors}
          name={"file"}
          render={({ message }) => (
            <FormHelperText
              style={{
                color: "#bf1650",
              }}
            >
              {message}
            </FormHelperText>
          )}
        />
      </div>
      <br />
    </div>
  );
};

export default Photo;
