import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActivityTrigggerOpen: false,
  isActivityUserAssignOpen: false,
  isActivityFormTitleDialogOpen: false,
  isActivityWebDialogOpen: false,
  isActivityScriptDialogOpen: false
};

const designerDialogsSlice = createSlice({
  name: "designerDialogs",
  initialState,
  reducers: {
    toggleActivityTriggerDialog: (state, action) => {
      return {
        ...state,
        isActivityTrigggerOpen: !state.isActivityTrigggerOpen,
      };
    },
    toggleActivityWebDialog: (state, action) => {
      return {
        ...state,
        isActivityWebDialogOpen: !state.isActivityWebDialogOpen,
      };
    },
    toggleActivityScriptDialog: (state, action) => {
      return {
        ...state,
        isActivityScriptDialogOpen: !state.isActivityScriptDialogOpen,
      };
    },
    toggleActivityUserAssignDialog: (state, action) => {
      return {
        ...state,
        isActivityUserAssignOpen: !state.isActivityUserAssignOpen,
      };
    },
    toggleActivityFormTitleDialog: (state, action) => {
      return {
        ...state,
        isActivityFormTitleDialogOpen: !state.isActivityFormTitleDialogOpen,
      };
    },
  },
});

const { reducer, actions } = designerDialogsSlice;
export const {
  toggleActivityTriggerDialog,
  toggleActivityUserAssignDialog,
  toggleActivityFormTitleDialog,
  toggleActivityWebDialog,
  toggleActivityScriptDialog
} = actions;
export default reducer;
