import { createSlice, current } from "@reduxjs/toolkit";
import { getProfileImagePath } from "../../network/networks";
const initialState = {};
const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      return {
        profile: {
          ...action.payload,
          profile_image: getProfileImagePath(action.payload.id),
        },
      };
    },
    checkPermission: (state, action) => {
      console.log("action", action);
      console.log("state", state);
    },
    refreshProfilePic: (state) => {
      state.profile.profile_image = getProfileImagePath(state.profile.id);
    },
  },
});
const { reducer, actions } = profileSlice;
export const { setProfile, getProfile, refreshProfilePic } = actions;
export default reducer;
