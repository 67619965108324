import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rdIsTrainingCompleted: false,
  selectedModelID: "",
  tableDataset: {},
  selectedDataSource: null,
  modelName: "",
  rdColumns: [],
  toSubmitFeatureTarget: {},
  rdPreditedSummaryData: {},
  rdSelectedTarget: null,
  rdselectedFeature: [],
};

const modelFlowSlice = createSlice({
  name: "modelFlow",
  initialState: initialState,
  reducers: {
    updateTableDataSet: (state, action) => {
      return {
        ...state,
        tableDataset: action.payload,
      };
    },
    updateIsModelTraining: (state, action) => {
      return {
        ...state,
        rdIsTrainingCompleted: action.payload,
      };
    },
    updatePredictedSummaryData: (state, action) => {
      return {
        ...state,
        rdPreditedSummaryData: action.payload,
      };
    },
    updateSelectedModelID: (state, action) => {
      return {
        ...state,
        selectedModelID: action.payload,
      };
    },
    updateSelectedDataSouce: (state, action) => {
      return {
        ...state,
        selectedDataSource: action.payload,
      };
    },
    updateModelName: (state, action) => {
      return {
        ...state,
        modelName: action.payload,
      };
    },
    updateToSubmitFeatureTarget: (state, action) => {
      return {
        ...state,
        toSubmitFeatureTarget: action.payload,
      };
    },
    resetState: (state, action) => {
      return {
        state: initialState,
      };
    },
    updateRDSelectedFeature: (state, action) => {
      return {
        ...state,
        rdselectedFeature: action.payload,
      };
    },
    updateRDSelectedTarget: (state, action) => {
      return {
        ...state,
        rdSelectedTarget: action.payload,
      };
    },
    updateModelAllData: (state, action) => {
      console.log("updateModelAllData called");
      return action.payload;
    },
    updateSomeFields: (state, action) => {
      let someFieldObj = action.payload ?? {};
      return {
        ...state,
        ...someFieldObj,
      };
    },
    updateRDColumns: (state, action) => {
      return {
        ...state,
        rdColumns: action.payload,
      };
    },
  },
});

const { reducer, actions } = modelFlowSlice;
export const {
  temp_updateTableDataSet,
  temp_updateSelectedDataSouce,
  temp_updateSelectedModelID,
  temp_updateModelName,
  temp_updateToSubmitFeatureTarget,
  temp_resetState,
  temp_updateRDSelectedFeature,
  temp_updateRDSelectedTarget,
  temp_updatePredictedSummaryData,
  temp_updateModelAllData,
  temp_updateIsModelTraining,
  temp_updateSomeFields,
  temp_updateRDColumns,
} = actions;
export default reducer;
