import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            key: "placeholder",
            ignore: true,
          },
          {
            key: "modalEdit",
            ignore: true,
          },
          {
            key: "autofocus",
            ignore: true,
          },
          {
            key: "tableView",
            ignore: true,
          },
          {
            key: "tooltip",
            ignore: true,
          },
          {
            key: "customClass",
            ignore: true,
          },
          {
            key: "tabindex",
            ignore: true,
          },
          {
            key: "hidden",
            ignore: true,
          },
        ],
      },
      {
        key: "data",
        ignore: true,
        components: [],
      },
      {
        key: "validation",
        ignore: true,
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        ignore: true,
        components: [],
      },
      {
        key: "layout",
        ignore: true,
        components: [],
      },
    ],
    ...extend
  );
};
