import { ErrorMessage } from "@hookform/error-message";
import { FormHelperText, Grid, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import CustomRSelect from "../../../../../../../components/CustomRSelect/CustomRSelect";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import StyledTextField from "../../../../../../../components/StyledTextField/StyledTextField";
import { appCountries } from "../../../../../../../network/networks";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import styled from "styled-components";

const DatePickerUI = styled.ul`
  .MuiTextField-root {
    width: 100%;
  }
  .MuiOutlinedInput-input {
    padding: 8px 10px;
  }
`;
const ApplicantInfo = () => {
  const {
    control,
    formState: { errors },
    clearErrors,
    getValues,
  } = useFormContext();

  return (
    <div>
      <Typography variant="h6">Applicant Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Applicants Surname / Family Name"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"surname"}
              id={"surname"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="select"
                    id="surname"
                    row={4}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"surname"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Applicants Given Names "}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"name"}
              id={"name"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="select"
                    id="name"
                    row={4}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"name"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"Applicants Gender"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"gender"}
              id={"gender"}
              control={control}
              render={({ field }) => {
                return (
                  <CustomRSelect
                    {...field}
                    fullWidth
                    type="select"
                    id="gender"
                    options={[
                      { label: "Male", value: "male" },
                      { label: "Female", value: "female" },
                    ]}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"gender"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Applicants Marital Status"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"maritalStatus"}
              id={"maritalStatus"}
              control={control}
              render={({ field }) => {
                return (
                  <CustomRSelect
                    {...field}
                    fullWidth
                    type="select"
                    id="maritalStatus"
                    options={[
                      { label: "Single", value: "Single" },
                      { label: "Married", value: "Married" },
                      { label: "Widow", value: "Widow" },
                      { label: "Divorced", value: "Divorced" },
                    ]}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"maritalStatus"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"Date Of Birth"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"dateOfBirth"}
              id={"dateOfBirth"}
              control={control}
              render={({ field }) => {
                return (
                  <DatePickerUI>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Date Of Birth"
                          fullWidth
                          {...field}
                          sx={{
                            "MuiTextField-root": {
                              width: "100%",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </DatePickerUI>
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"dateOfBirth"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Applicants Place of Birth"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"placeOfBirth"}
              id={"placeOfBirth"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="select"
                    id="placeOfBirth"
                    row={4}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"placeOfBirth"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">{"Telephone Number"}</FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"mobileno"}
              id={"mobileno"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="text"
                    id="mobileno"
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"mobileno"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"El Salvador Phone Number"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"kenyanPhoneNo"}
              id={"kenyanPhoneNo"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="text"
                    id="kenyanPhoneNo"
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"kenyanPhoneNo"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="form-group">
            <FormHelperText color="muted">
              {"Applicants Current Occupation/Profession"}
            </FormHelperText>
            <Controller
              rules={{ required: "This field is required." }}
              name={"occupation"}
              id={"occupation"}
              control={control}
              render={({ field }) => {
                return (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="text"
                    id="occupation"
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={"occupation"}
              render={({ message }) => (
                <FormHelperText
                  style={{
                    color: "#bf1650",
                  }}
                >
                  {message}
                </FormHelperText>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </div>
  );
};

export default ApplicantInfo;
