import { Box, createTheme, LinearProgress, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoMonogram from "./LogoMonogram";
import "./styles.scss";

/* <div className="loader-container">
        <span className="animated-loader-txt animated-loader-txt-1" />
        <div className="animated-loader-txt animated-loader-txt-2">a</div>
        <div className="animated-loader-txt animated-loader-txt-3">i</div>
        <div className="animated-loader-txt animated-loader-txt-4">r</div>
        <div className="animated-loader-txt animated-loader-txt-5">a</div>
        <div className="animated-loader-txt animated-loader-txt-6">°</div>
      </div> */

//background: "#ffaf00",
//secondary="#6755DF"

let primary = "#6755DF";
let secondary = "#ffaf00";

const useStyles = makeStyles({
  root: {
    height: "40px",
    "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
      backgroundColor: primary,
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: primary,
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: secondary,
    },
    "& .MuiLinearProgress-dashedColorPrimary": {
      backgroundImage: "radial-gradientprimary0%,primary16%, transparent 42%)",
    },
  },
});

const linearProgressBarTheme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
        },
      },
    },
  },
});

const LoaderSmall = ({ loading }) => {
  const classes = useStyles();
  if (loading) {
    return (
      <div className="loader-samll-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LogoMonogram className="animated-loader-txt animated-loader-txt-1" />
          <div style={{ width: "100%" }} className="mt-3">
            <ThemeProvider theme={linearProgressBarTheme}>
              <LinearProgress
                classes={{
                  root: classes.root,
                }}
                style={{
                  height: "6px",
                  width: "100px",
                  borderRadius: "10px",
                }}
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    );
  } else {
    return <div style={{ display: "none" }} />;
  }
};
export default LoaderSmall;
