import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination, TableRow, Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import Images from "../../../assets";
import Search from "../../../components/Search/Search";
import ESTablePaginationAction from "../../../esCommonComponent/ESTablePaginationAction";
import { elisCostCenter } from "../../../network/networks";
import Colors from "../../../values/colors";
import '../styles.css';
import Strings from "../../../values/strings";
const costCenterHeader = [
  { id: "KOSTL", label: Strings.id },
  { id: "KTEXT", label: Strings.entity },
];
const CostCenterDialog = (props) => {
  const { onClose, open, onSelectItem, setShowDialogLoader, showDialogLoader, isWebEntry, pageContent } = props;
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [costCenterData, setCostCenterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [noResultFound, setNoResultFound] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [s_id, setS_id] = useState("")
  const [s_id_debounce] = useDebounce(s_id, 1500)
  const [s_entity, setS_entity] = useState("")
  const [s_entity_debounce] = useDebounce(s_entity, 1500)
  const [selectedRow, setSelectedRow] = useState("");
  const formAxiosInstance = axios.create();
  formAxiosInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${isWebEntry ? pageContent.token.token : localStorage.getItem('token')}`;
    return config;
  });
  const fetchCostCenterList = useCallback(() => {
    setCostCenterData([])
    setIsFetchingData(true);
    let dataToSubmit = {
      limit: rowsPerPage,
      page: page + 1,
      // s_id: s_id_debounce,
      // s_entity: s_entity_debounce
    }


    formAxiosInstance.get(elisCostCenter, { params: dataToSubmit }).then((res) => {
      setTotalCount(res.data.meta.total)
      setCostCenterData(res.data.data)
      setNoResultFound(_.isEmpty(res.data.data));
      setIsFetchingData(false);
    }).catch(() => {
      setNoResultFound(true);
      setIsDataFetching(false);
    });
  }, [page, rowsPerPage]);
  const filterCostCenterList = useCallback(() => {
    setCostCenterData([])
    setIsFetchingData(true);
    let dataToSubmit = {
      limit: rowsPerPage,
      page: 1,
      s_id: s_id_debounce,
      s_entity: s_entity_debounce
    }
    formAxiosInstance.get(elisCostCenter, { params: dataToSubmit }).then((res) => {
      setTotalCount(res.data.meta.total)
      setCostCenterData(res.data.data)
      setNoResultFound(_.isEmpty(res.data.data));
      setIsFetchingData(false);
    }).catch(() => {
      setNoResultFound(true);
      setIsDataFetching(false);
    });
  }, [rowsPerPage, s_entity_debounce, s_id_debounce]);
  useEffect(() => {
    fetchCostCenterList();
  }, [fetchCostCenterList]);
  const onRowSelect = (item) => {
    setShowDialogLoader(true)
    let data = {
      label: item.KTEXT,
      value: item.KOSTL
    }
    onSelectItem(data)
    onClose()
  }
  const onClear = () => {
    setPage(0)
    setS_id("")
    setS_entity("")
    fetchCostCenterList()
  }
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
      fullWidth
      style={
        {
          zIndex: "9999999",
        }
      }
      disableEscapeKeyDown
    >
      <DialogTitle sx={{
        bordercostCentertom: "1px solid #e4e0e0", padding: "8px 10px",
        borderBottom: "1px solid #c9c6c6",
        marginBottom: "3px",
      }}>
        {Strings.cost_center}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: "10px !important",
              top: "6px !important",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "1opx !important" }} >
        {
          showDialogLoader &&
          <Backdrop
            sx={{ color: Colors.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showDialogLoader}
            onClick={() => setShowDialogLoader(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        <Grid container spacing={2}>
          <Grid item xs={3} md={3} sm={3} sx={{ paddingLeft: "5px !important" }}>
            <div style={{
              // height: "100%"
            }}>
              <div>
                <FormHelperText>{Strings.id}</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(1);
                    if (_.isEmpty(e.target.value)) {
                      setS_id(e.target.value);
                    }
                    setS_id(e.target.value);
                  }}
                  value={s_id}
                  placeholder={`${Strings.search} ${Strings.id}`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
              <div>
                <FormHelperText>{Strings.entity}</FormHelperText>
                <Search
                  handleSearchEvent={(e) => {
                    // setPage(1);
                    if (_.isEmpty(e.target.value)) {
                      setS_entity(e.target.value);
                    }
                    setS_entity(e.target.value);
                  }}
                  value={s_entity}
                  placeholder={`${Strings.search} ${Strings.entity}`}
                  sxStyleProps={{
                    padding: "0px 0px",
                    ".MuiOutlinedInput-input": {
                      fontSize: "12px"
                    },
                    ".MuiSvgIcon-root": {
                      width: "24px",
                      height: "18px"
                    },
                    ".MuiInputBase-formControl": {
                      padding: "0px 0px",
                      borderRadius: "10px",
                      height: "30px !important",
                      marginBottom: "5px"
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ textAlign: "end", marginTop: "10px" }}>
              <Button size="small" variant="outlined" autoFocus
                sx={{ mr: 1 }}
                onClick={() => {
                  setPage(0)
                  filterCostCenterList()
                }}>
                {Strings.apply}
              </Button>
              <Button size="small" variant="outlined" color="error" onClick={() => onClear()}>
                {Strings.clear}
              </Button>
            </div>
          </Grid>

          <Grid item xs={9} md={9} sm={9}>
            <div>
              <Card style={{ boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 1px 0px 1px rgb(0 0 0 / 14%), 0px -1px 0px 0px rgb(0 0 0 / 12%)" }}>
                <CardContent sx={{ padding: "0px !important" }}>
                  <TableContainer style={{ maxHeight: "500px" }}>
                    <Table stickyHeader>
                      <TableHead style={{ textTransform: "capitalize" }} >
                        <TableRow>
                          {costCenterHeader.map((headerItem, headerItemIndex) => {
                            return (
                              <TableCell
                                key={headerItemIndex}
                                style={{
                                  fontSize: "12px",
                                  textAlign: "left",
                                  fontFamily: "Inter",
                                  fontWeight: 600,
                                  whiteSpace: "nowrap",
                                  color: "white",
                                  backgroundColor: Colors.airaPrimary,
                                  padding: "10px"
                                }}
                              >
                                {headerItem.label}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {isFetchingData &&
                          _.range(rowsPerPage).map((rowNumber) => (
                            <TableRow key={rowNumber}>
                              {_.range(costCenterHeader.length).map((keyNumber) => (
                                <TableCell
                                  style={{ minWidth: "100px" }}
                                  className="p-1"
                                  key={`${rowNumber}.${keyNumber}`}>
                                  <Skeleton
                                    variant="rectangular"
                                    height={24}
                                    sx={{ borderRadius: "12px" }}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        {!isFetchingData && noResultFound && (
                          <TableRow style={{ padding: '0px !important' }}>
                            <TableCell colSpan={8} className="p-1">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  src={Images.emptyState}
                                  style={{
                                    alignSelf: "center",
                                    //width: "100%",
                                    width: "300px",
                                    height: "300px",
                                  }}
                                  alt=""
                                />
                                <Typography
                                  component="span"
                                  className="styled-text Bonjour-Julien"
                                >
                                  Nothing here
                                </Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}


                        {costCenterData.map((costCenterRowItem) => {
                          return (
                            <TableRow
                              onClick={(e) => {
                                setSelectedRow(costCenterRowItem)
                                if (e.detail == 2) {
                                  onRowSelect(costCenterRowItem)
                                }
                              }}
                              hover
                              style={{
                                cursor: "pointer",
                                background: selectedRow.KOSTL == costCenterRowItem.KOSTL ? "#dad9d9" : "none"
                              }}
                              key={costCenterRowItem.costCenter_id}>
                              {costCenterHeader.map((costCenterHeaderItem) => {
                                let value = costCenterRowItem[costCenterHeaderItem.id];
                                return (
                                  <TableCell
                                    key={costCenterHeaderItem.id}
                                    className="p-2"
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "left",
                                      fontFamily: "Inter",
                                      fontWeight: 500,
                                      whiteSpace: "nowrap",
                                      // bordercostCentertom: "1px solid #eee",
                                    }}
                                  >
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ float: "right", fontFamily: "Inter" }}>
                    <TablePagination
                      labelRowsPerPage={Strings.rows_per_page}
                      component="div"
                      rowsPerPageOptions={[5, 10, 20]}
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={(event, newPage) => {
                        setPage(newPage);
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                      }}
                      ActionsComponent={ESTablePaginationAction}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </DialogContent >
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={() => {
          if (!_.isEmpty(selectedRow))
            onRowSelect(selectedRow)
        }
        }>
          {Strings.validate}
        </Button>
        <Button variant="outlined" color="error" onClick={onClose}>
          {Strings.cancel}
        </Button>
      </DialogActions>
    </Dialog >
  );
};

CostCenterDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CostCenterDialog;
