import { Box, createTheme, LinearProgress, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
//import "./styles.scss";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const LogoMonogram = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        fill="#6755DF"
        d="M13.75 80C6.16 80 0 73.84 0 66.25S6.16 52.5 13.75 52.5 27.5 58.66 27.5 66.25 21.34 80 13.75 80z"
      />
      <path
        fill="#FFAF00"
        d="M54.905 72.133a13.48 13.48 0 0 0 1.47 2.534C58.764 77.893 62.57 80 66.875 80 74.12 80 80 74.027 80 66.667c0-1.947-.42-3.814-1.155-5.494L52.595 7.867C50.548 3.227 45.954 0 40.625 0 33.38 0 27.5 5.973 27.5 13.333c0 1.947.42 3.814 1.155 5.494l26.25 53.306z"
      />
    </g>
  </svg>
);

/* <div className="loader-container">
        <span className="animated-loader-txt animated-loader-txt-1" />
        <div className="animated-loader-txt animated-loader-txt-2">a</div>
        <div className="animated-loader-txt animated-loader-txt-3">i</div>
        <div className="animated-loader-txt animated-loader-txt-4">r</div>
        <div className="animated-loader-txt animated-loader-txt-5">a</div>
        <div className="animated-loader-txt animated-loader-txt-6">°</div>
      </div> */

//background: "#ffaf00",
//secondary="#6755DF"

let primary = "#6755DF";
let secondary = "#ffaf00";

const useStyles = makeStyles({
  root: {
    height: "40px",
    "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
      backgroundColor: primary,
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: primary,
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: secondary,
    },
    "& .MuiLinearProgress-dashedColorPrimary": {
      backgroundImage: "radial-gradientprimary0%,primary16%, transparent 42%)",
    },
  },
});

const linearProgressBarTheme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
        },
      },
    },
  },
});

const AzureConnectionLoader = ({ loading = true }) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [azureCode, setAzureCode] = useState(null);

  //This is the Code for Redirect URL for the generated code after Azure connection
  // I am setting value of code and scopes in localstorage and added a setinterval for 2sec in Gactivity component
  // when we find code and scope in localstorage it instantly calls an API for creating connection and deletes localstorage values
  useEffect(() => {
    let _azureCode = searchParams.get("code");

    if (!_.isEmpty(_azureCode)) {
      console.log("In here")
      localStorage.setItem("azureCode", _azureCode);
      setAzureCode(_azureCode)
    }
  }, [searchParams]);


  if (loading) {
    return (
      <div className="loader-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LogoMonogram className="animated-loader-txt animated-loader-txt-1" />
          <div style={{ width: "100%" }} className="mt-3">
            <ThemeProvider theme={linearProgressBarTheme}>
              <LinearProgress
                classes={{
                  root: classes.root,
                }}
                style={{
                  height: "6px",
                  width: "100px",
                  borderRadius: "10px",
                }}
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    );
  } else {
    return <div style={{ display: "none" }} />;
  }
};

export default AzureConnectionLoader;
