import { createSlice } from "@reduxjs/toolkit";
const formVariableSlice = createSlice({
  name: "formVariables",
  initialState: {
    variables: {},
  },
  reducers: {
    setFormBuilderVariables: (state, action) => {
      console.log("action", action.payload);
      return { variables: action.payload };
    },
  },
});

const { reducer, actions } = formVariableSlice;
export const { setFormBuilderVariables } = actions;
export default reducer;
