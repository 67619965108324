import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {},
      styleOverrides: {
        root: {
          textTransform: "none",
          //height: "60px",
          fontFamily: "Poppins",
          fontWeight: 600,
          borderRadius: "5px",
          padding: "5px 20px",
          border: "1px solid #6755df",
          "&.common-btn": {
            backgroundColor: "white",
            color: "#6755df",
          },
          "&.common-btn:hover": {
            backgroundColor: "#6755df",
            color: "white",
          },
          "&.common-btn-cta": {
            backgroundColor: "#6755df",
            color: "white",
          },
          "&.common-btn-cta:hover": {
            backgroundColor: "white",
            color: "#6755df",
          },
        },
      },
    },
  },
});

const CommonButton = ({ isCTA, ...restProps }) => {
  // CTA refers to call to action i.e. buttons like submit or any button that performs an action on click
  return (
    <ThemeProvider theme={theme}>
      <Button
        className={isCTA ? "common-btn-cta" : "common-btn"}
        {...restProps}
      />
    </ThemeProvider>
  );
};

export default CommonButton;
