import { alpha, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Theme } from "@mui/material/styles";
import "@szhsin/react-menu/dist/index.css";
import { AppRouter, Routes } from "auth-react-router";
import axios from "axios";
import _ from "lodash";
import { ConfirmProvider } from "material-ui-confirm";
import { useCallback, useEffect, useMemo, useState } from "react";
import "react-activity/dist/library.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RootState } from "../src/redux/store";
import { getCurrentSocketURL } from "./common/esutil";
import { routes } from "./common/routes";
import { initializeApp } from "./network/axiosInitializer";
import { domainUrl, getUploadedAssets } from "./network/networks";
import { setAssets } from "./redux/slices/assetsSlice";
import { setInitialMode } from "./redux/slices/themeSlice";
import Strings from "./values/strings";
declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const useAuthProvider = () => {
  return {
    isAuth: localStorage.getItem("token") !== null ? true : false,
  };
};
const App = () => {
  const { isAuth } = useAuthProvider();
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const selectedLanguage = useSelector(
    (state: RootState) => state.other.selectedLanguage
  );
  const isDarkMode = useSelector(
    (state: RootState) => state.theme.isDarkMode,
    _.isEqual
  );
  const dispatch = useDispatch();
  useEffect(() => {
    let lsTheme = localStorage.getItem("isDarkMode");
    if (_.isNil(lsTheme)) {
      lsTheme = "false";
    }
    dispatch(
      setInitialMode({
        isDarkMode: lsTheme === "true",
      })
    );
  }, [dispatch]);

  Strings.setLanguage(selectedLanguage);
  useEffect(() => {
    initializeApp();
  }, []);

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: isDarkMode ? "dark" : "light",
        primary: {
          //main: "#6755DF",
          main: "#98b1ff",
        },
        error: {
          main: "#f44336",
        },
      },
      ...{
        typography: {
          fontFamily: `"Inter","Poppins", "Arial", sans-serif`,
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
        components: {
          MuiCheckbox: {
            styleOverrides: {
              root: {
                "&.Mui-checked": {
                  color: "#98b1ff",
                },
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              switchBase: {
                "&.Mui-checked": {
                  color: "#98b1ff",
                  "&:hover": {
                    backgroundColor: alpha("#98b1ff", 0.2),
                  },
                },
                "&.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#98b1ff",
                },
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: "14px",
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                color: isDarkMode ? "#fff" : "#200E32",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "& .MuiIconButton-root": {
                  /* height: "40px",
            width: "40px", */
                  padding: "5px",
                  position: "absolute",
                  right: 16,
                  top: 16,
                  fontWeight: "bold",
                  //boxShadow: "0px 0px 4px gray",
                  ":hover": {
                    boxShadow: "0px 0px 2px gray",
                  },
                },
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: "14px",
              },
            },
          },
        },
      },
    });
  }, [isDarkMode]);

  const getUploadedDetails = useCallback(() => {
    axios
      .get(getUploadedAssets)
      .then((res) => {
        let title = res.data.find((_item: any) => _item.type === "TITLE");
        let favicon = res.data.find((_item: any) => _item.type === "FAVICON");
        if (!_.isNil(title)) setTitle(title.value);
        if (!_.isNil(favicon)) {
          const _favicon = document.getElementById(
            "favicon"
          ) as HTMLAnchorElement | null;
          if (_favicon != null) {
            const href = _favicon.href;
            _favicon.href = `${domainUrl}assets${favicon.value}`;
          }
          setIcon(`${domainUrl}assets${favicon.value}`);
        }

        let _monogram = res.data.find(
          (_item: any) => _item.type === "HOLOGRAM"
        );
        let _logo_light = res.data.find(
          (_item: any) => _item.type === "LOGO_LIGHT"
        );
        let _logo_dark = res.data.find(
          (_item: any) => _item.type === "LOGO_DARK"
        );

        dispatch(
          setAssets({
            title: _.isNil(title?.value) ? "" : title?.value,
            logo_light_img: _.isNil(_logo_light?.value)
              ? "/logo.png"
              : `${domainUrl}assets${_logo_light?.value}`,
            logo_dark_img: _.isNil(_logo_dark?.value)
              ? "/logoDark.png"
              : `${domainUrl}assets${_logo_dark?.value}`,
            favicon_img: _.isNil(favicon?.value)
              ? "/logo192.png"
              : `${domainUrl}assets${favicon?.value}`,
            monogram_img: _.isNil(_monogram?.value)
              ? "/logo-monogram.png"
              : `${domainUrl}assets${_monogram?.value}`,
          })
        );
      })
      .catch((err) => {});
  }, [dispatch]);

  useEffect(() => {
    getUploadedDetails();
  }, [getUploadedDetails]);

  useEffect(() => {
    console.log("checking socket url live", getCurrentSocketURL());
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title ? title : "AIRA"}</title>
      </Helmet>
      <BrowserRouter>
        <ToastContainer limit={1} theme={isDarkMode ? "dark" : "light"} />
        {/* `userRole` is optional, use it only if at least one Route has the `roles` property */}
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          {/* @ts-ignore */}
          <AppRouter isAuth={isAuth} routes={routes} userRole={"admin"}>
            {/* Wrap `Routes` component into a Layout component or add Header */}
            <ConfirmProvider>
              <Routes />
            </ConfirmProvider>
          </AppRouter>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
