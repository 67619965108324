import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog, DialogContent,
  DialogTitle, IconButton
} from "@mui/material";
import ForgotPassword from "./ForgotPassword";

const DialogForgotPassword = ({
  open,
  handleClose,
  ...restProps
}) => {

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      {...restProps}
    >
      <DialogTitle>
        Forgot Password
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ForgotPassword
          handleClose={handleClose}
          isDialog={true}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DialogForgotPassword;

