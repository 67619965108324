import _ from "lodash";
import { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import CustomRSelect from "../../../../../../components/CustomRSelect/CustomRSelect";
import settingsForm from "./settings";

let dummy = [
  // {
  //   value: "chocolate",
  //   label: "Chocolate",
  // },
];
const SelectComponent = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props?.value ?? "",
      inputValue: "",
      data: [],
      optionsData: !_.isNil(this?.props?.component?.data?.values)
        ? this?.props?.component?.data?.values
        : !_.isNil(this?.props?.component?.myCustomSetting) &&
          !_.isEmpty(this?.props?.component?.myCustomSetting)
        ? this.isValidJson(this?.props?.component?.myCustomSetting)
          ? JSON.parse(this?.props?.component?.myCustomSetting ?? dummy)
          : dummy
        : dummy,
    };
    console.log("props", this.props);
  }
  isValidJson(json) {
    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      return false;
    }
  }
  setValue = (obj) => {
    this.setState(
      (prevState) => ({ value: obj }),
      () => {
        this.props.onChange(obj.value);
      }
    );
  };
  uniq = (arr, track = new Set()) => {
    let res = arr.filter(({ value }) =>
      track.has(value) ? false : track.add(value)
    );
    return res;
  };
  render() {
    return (
      <>
        <CustomRSelect
          options={
            this?.props?.component?.unique
              ? this.uniq(this.state.optionsData)
              : this.state.optionsData
          }
          // menuPortalTarget={document.body}
          menuPortalTarget={document.querySelector(".MuiDialog-root")}
          onChange={this.setValue}
          value={this.state.optionsData.find(
            (item) => item.value === this.state.value
          )}
          otherProp={{
            labelId: this?.props?.component?.key,
            isDisabled: this?.props?.component?.disabled,
            readOnly: this?.props?.component?.disabled,
            id: this?.props?.component?.key,
            label: this?.props?.component?.label,
            fullWidth: true,
            isMulti: this?.props?.component?.multiple,
          }}
          fullWidth
        />
      </>
    );
  }
};

export default class SelectComponentField extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation:  string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Dropdown",
      icon: "square",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: SelectComponentField.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   * @param component
   * @param options
   * @param data
   * @param sources
   * @returns {*}
   */
  init() {
    super.init();
  }
  get inputInfo() {
    const info = super.inputInfo;
    return info;
  }
  static schema() {
    return ReactComponent.schema({
      type: "SelectComponent",
      label: "SelectComponent",
      key: "select",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <SelectComponent
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
