import { configureStore } from "@reduxjs/toolkit";
import othersSlice from "./other/othersSlice";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import themeReducer from "./slices/themeSlice";
import designerDialogsReducer from './slices/designerDialogsSlice'
import casesVariablesReducer from './slices/casesVariablesSlice'
import modelFlowReducer from './slices/modelFlowSlice'
import profileSlice from './slices/profileSlice'
import permissionsSlice from './slices/permissionsSlice'
import assetsSlice from './slices/assetsSlice'
import formVariableSlice from "./slices/formVariableSlice"
import idpSlice from "./slices/idpSlice"
import recordSlice from "./slices/recordSlice"
import recordForegroundSlice from "./slices/recordForegroundSlice"

const reducer = {
  auth: authReducer,
  message: messageReducer,
  theme: themeReducer,
  designerDialogs: designerDialogsReducer,
  casesVariables: casesVariablesReducer,
  modelFlow: modelFlowReducer,
  other: othersSlice,
  profile: profileSlice,
  permissions: permissionsSlice,
  assets: assetsSlice,
  formVariables: formVariableSlice,
  idp: idpSlice,
  isrecording: recordSlice,
  isRecInForeground: recordForegroundSlice,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
